<template>
  <div class="container_vip">
    <div class="vipImg_v">
      <div class="center" @click="centerBtn">个人中心</div>
    </div>

    <!-- 福利商城 -->
    <tab-select @selectBtn="selectBtn"></tab-select>

    <!-- 新人专享 -->
    <new-goods @ruchBtn="ruchBtn" :userFrom="userFrom" :vipImg="vipImg" @onLoad="onLoad" @fetch="fetch" :finished="finished" :dataList="dataList"></new-goods>

    <!-- 弹窗提示 -->
    <dispose :userType="userType" @imgBtn="imgBtn" @payBtn="payBtn" @cloneBtn="cloneBtn" :config="config" :dataList="userFrom" v-if="disposeShow"></dispose>
  </div>
</template>
<script>
import tabSelect from "../../components/goods/tabSelect";
import newGoods from "../../components/goods/new";
import dispose from "../../components/goods/dispose";
import { goodsList_api } from "@/api/deal";
import { getuser_api } from "@/api/common";
export default {
  data() {
    return {
      disposeShow: false,
      vipImg: null,
      dataList: {
        goods: [],
        gifts: []
      },
      query: {
        limit: 10,
        page: 1,
        goods_sale_range: 1
      },
      finished: false,
      userFrom: null,
      config: {
        text: "",
        text1: "",
        status: ""
      },
      id: "",
      userType:1,//商品类型
    };
  },
  components: {
    tabSelect,
    newGoods,
    dispose
  },
  created() {
    document.title = "福利专区";
    this.getList();
    this.getUser();
  },
  methods: {
    cloneBtn(){
      this.disposeShow = false
    },
    centerBtn() {
      this.$router.push("../lador/ladorLogin");
    },
    getUser() {
      getuser_api().then(res => {
        console.log(res, "res");
        if (res.code == 0) {
          this.userFrom = res.data;
          console.log(new Date().getTime()>(new Date(this.userFrom.union_expire_at).getTime()))
          if(this.userFrom.union_expire_at){
            if(new Date().getTime()>(new Date(this.userFrom.union_expire_at).getTime())){
                this.userFrom.vipUser = 1
            }else{
                this.userFrom.vipUser = 0
            }
          }else{
            this.userFrom.vipUser = 3
          }
          if(this.userFrom.union_shareholder_expire_at){
              if(new Date().getTime()>new Date(this.userFrom.union_shareholder_expire_at).getTime()){
                  this.userFrom.vipShare = 1
              }else{
                  this.userFrom.vipShare = 0
              }
          }else{
            this.userFrom.vipShare = 3
          }
          // 正常情况下购买
          // 用户是会员，不是股东。会员正常
          if(this.userFrom.union_member!=0&&this.userFrom.union_shareholder==0&&this.userFrom.union_member_freeze==0){
              this.userFrom.userStatus = 1
          }

          if(this.userFrom.union_member==0&&this.userFrom.union_shareholder==0){
              this.userFrom.userStatus = 6
          }
          // 异常情况
          // 平台手动冻结
          
          if(this.userFrom.vipUser==0&&this.userFrom.vipShare==0&&this.userFrom.union_member_freeze==1){
            this.userFrom.userStatus = 2
          }
          // 用户是会员，不是股东。平台冻结会员
          if(this.userFrom.vipUser==0&&this.userFrom.vipShare==3&&this.userFrom.union_member_freeze==1){
            this.userFrom.userStatus = 2
          }
          // 会员/股东到期冻结
          if(this.userFrom.vipUser==1||this.userFrom.vipShare==1){
              this.userFrom.userStatus = 3
          }

          // 冻结状态购买按钮判断
          if(this.userType==1){
              if(this.userFrom.union_member!=0){
                  this.userFrom.confimBtn = true
              }else{
                this.userFrom.confimBtn = false
              }
          }else{
              if(this.userFrom.union_shareholder!=0){
                this.userFrom.confimBtn = true
              }else{
                this.userFrom.confimBtn = false
              }
          }
          
          console.log(this.userFrom,"this.userFrom")
          console.log(this.userFrom.confimBtn,"213123")
        }
      });
    },
    getList() {
      goodsList_api(this.query).then(res => {
        if (res.code == 0) {
          this.dataList.goods = this.dataList.goods.concat(res.data.goods);
          this.dataList.gifts = res.data.gifts;
          console.log(this.dataList, 21312);
        }
      });
    },
    imgBtn() {
      this.disposeShow = false;
    },
    selectBtn(data) {
      this.query.page = 1;
      this.dataList.goods = [];
      console.log(data);
      this.query.goods_sale_range = data ? 1 : 2;
      this.userType = data ? 1 : 2;
      this.getList();
    },
    onLoad() {
      if (this.query.page <= 10) {
        this.fetch();
      } else {
        // all data loaded
        this.finished = true;
      }
    },
    fetch() {
      this.query.page++;
      console.log(this.query.page);
      // this.getList();
    },
    ruchBtn(id, type) {
      this.config.status = type;
      this.id = id;
      console.log(this.config.status, "1",type);
      this.getUser()
      // 礼包商品
      if(type==1){
        if((this.userType>=1&&this.userFrom.union_member==0)||(this.userFrom.union_shareholder==0&&this.userType==2)){
          if(this.userFrom.vipUser==0&&this.userFrom.vipShare==3&&this.userFrom.union_member_freeze==1){
            this.userFrom.userStatus = 5
            this.userFrom.confimBtn = false
          }
          this.disposeShow = true;
          return false;
        }
      }else{
        
        // 常规商品
      if(this.query.goods_sale_range==1){
        if (this.userFrom.union_member == 0||this.userFrom.union_member_freeze == 1) {
          this.disposeShow = true;
          return false;
        }
      }else{
        if (this.userFrom.union_shareholder == 0||this.userFrom.union_member_freeze == 1) {
          this.disposeShow = true;
          return false;
        }
      }
      if(this.userFrom.vipUser==1||this.userFrom.vipShare==1){
        this.disposeShow = true;
          return false;
      }
      if (this.userFrom.union_member_freeze == 1) {
          this.disposeShow = true;
          return false;
      }
      }
      
      this.$router.push(`/vipUser/goodsDetails?id=${id}`);
    },
    payBtn() {
      this.$router.push(`/vipUser/goodsDetails?id=${this.id}`);
    }
  }
};
</script>
<style>
.container_vip {
  background-color: #fafafa;
}
.vipImg_v {
  width: 750px;
  height: 750px;
  position: relative;
  background-image: url("../../assets/goods.png");
  background-size: 100% 100%;
}
.center {
  width: 200px;
  height: 63px;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 32px 32px 32px 32px;
  opacity: 1;
  font-size: 35px;
  color: #fff;
  line-height: 63px;
  text-align: center;
  position: absolute;
  top: 28px;
  right: 28px;
}
</style>
