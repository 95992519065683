<template>
  <div class="dis_pose_box">
    <div class="dis_box">
      <div class="dis_img">
        <img src="../../assets/lingdang.png" />
      </div>
      <div v-if="loginShow">
        <div class="dis_title">温馨提示</div>
        <div v-if="userFrom.union_member==0">
          <div class="dis_text">您不是会员，请注册会员</div>
          <div class="disBtn">
            <div @click="goVip">去注册会员</div>
          </div>
        </div>
        <div v-if="userFrom.union_member!=0&&userFrom.union_shareholder==0">
          <div class="dis_text">您不是股东，请注册股东</div>
          <div class="disBtn">
            <div @click="goShare">去注册股东</div>
          </div>
        </div>
      </div>
      <div v-else>
        <div>
          <div class="dis_title">温馨提示</div>
          <!-- 身份到期 -->
          <div class="dis_text" v-if="dataList.userStatus==2">当前身份权限平台已冻结将以原价购买</div>
          <div class="dis_text" v-if="dataList.userStatus==3">当前身份权限已到期将以原价购买</div>
          
          <!-- 身份未达标 -->
          <div class="dis_text" v-if="config.status==1&&dataList.union_member==0">您不是会员无法购买</div>
          <div class="dis_text" v-if="dataList.userStatus==6&&config.status!=1">您不是会员无法购买</div>
          <div class="dis_text" v-if="dataList.userStatus==1&&dataList.union_member!=0">您不是股东无法购买</div>
          <div class="dis_text" v-if="dataList.userStatus==5">您不是股东无法购买</div>
          <!-- -->
          <div v-else>{{config.text1}}</div>
        </div>
        <div class="disBtn">
          <div class="userBtn" v-if="dataList.confimBtn" @click="payBtn">确认购买</div>
          <div class="userBtn" v-if="dataList.userStatus==3" @click="userBtn">延期身份</div>
          <div class="userBtn" v-if="dataList.userStatus==2" @click="userBtn(3)">联系客服</div>
          <div class="userBtn" v-if="(dataList.userStatus==1&&dataList.union_member!=0)||dataList.userStatus==5" @click="userBtn(1)">我要升级</div>
          <div class="userBtn" v-if="config.status==1&&dataList.union_member==0" @click="userBtn(2)">注册会员</div>
          <div class="userBtn" v-if="config.status!=1&&dataList.userStatus==6" @click="userBtn(2)">注册会员</div>
        </div>
      </div>

    </div>
    <div class="img_box" @click="imgBtn">
      <img src="../../assets/cloneBtn5.png" />
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      isShare: true,
      isfreeze: true,
      isUser: false
    };
  },
  props: {
    dataList: {
      type: Object
    },
    config: {
      type: Object
    },
    userType: {
      type: Number
    },
    loginShow: {
      type: Boolean
    },
    userFrom:{
      type: Object
    }
  },
  methods: {
    imgBtn() {
      this.$emit("imgBtn");
    },
    payBtn() {
      this.$emit("payBtn");
    },
    userBtn(index) {
      if(index==1){
        this.$router.push("/index/share");
      }else if(index==2){
        this.$router.push("/index/index")
      }else if(index==3){
        this.$emit("cloneBtn")
      }else{
        if(this.dataList.vipUser==1){
          this.$router.push("../expenditure/vipUser?status=1&type=1");
          return false
        }
        if(this.dataList.vipShare==1){
          this.$router.push("../expenditure/vipUser?status=2")
        }
      }
      
    },
    goVip(){
      this.$router.push("/lador/index")
    },
    goShare(){
      this.$router.push("/index/share")
    }
  }
};
</script>
<style>
.dis_pose_box {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
}
.dis_box {
  width: 720px;
  height: 560px;
  background: linear-gradient(180deg, #ffe5c5 0%, #ffffff 100%);
  border-radius: 20px 20px 20px 20px;
  opacity: 1;
  border: 10px solid;
  border-image: linear-gradient(
      180deg,
      rgba(255, 180, 157, 1),
      rgba(253, 124, 108, 1)
    )
    10 10;
  margin: 250px auto 0;
  position: relative;
  text-align: center;
}
.dis_img {
  position: absolute;
  top: -100px;
  left: 50%;
  transform: translateX(-50%);
  width: 155px;
  height: 190px;
}
.dis_img img {
  width: 100%;
  height: 100%;
  transform: rotate(15deg);
}
.dis_title {
  font-size: 50px;
  font-weight: bold;
  margin-top: 150px;
}
.dis_text {
  font-size: 35px;
  width: 340px;
  margin: 20px auto 50px;
}
.disBtn {
  display: flex;
}
.disBtn div {
  width: 278px;
  height: 106px;
  border-radius: 50px 50px 50px 50px;
  opacity: 1;
  border: 2px solid #f54334;
  font-size: 40px;
  color: #f54334;
  text-align: center;
  line-height: 106px;
  margin: 20px auto;
}
.disBtn .userBtn {
  width: 278px;
  height: 106px;
  background: #f54334;
  border-radius: 50px 50px 50px 50px;
  opacity: 1;
  color: #fff;
  margin-left: 30px;
  margin: 0 auto;
}
.img_box {
  width: 50px;
  height: 50px;
  position: absolute;
  top: 130px;
  right: 50px;
}
.img_box img {
  width: 100%;
  height: 100%;
}
</style>
