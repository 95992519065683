<template>
    <div class="itemText">
        <div @click="selectBtn(true)" :class="selectItem?'select':''">会员专区</div>
        <div @click="selectBtn(false)" :class="selectItem==false?'select':''">股东专区</div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            selectItem:true
        }
    },
    methods: {
        selectBtn(status){
            this.selectItem = status
            this.$emit("selectBtn",status)
        }
    }
}
</script>
<style>

.itemText{
    display: flex
}
.itemText div{
    font-size: 32px ;color:#C6C6C6;
    margin: 20px 30px
}
.itemText .select{
    color:#2A2828;
    border-bottom: 5px solid #2A2828
}
</style>