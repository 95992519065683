<template>
  <div>
    <div class="newBox" v-if="dataList.gifts.length>0">
      <div class="new_title">新人礼包 福利享不停</div>
      <div>
        <van-swipe class="my-swipe" :autoplay="3000" indicator-color="rgba(229, 193, 135, 1)">
          <van-swipe-item v-for="(item,index) in dataList.gifts" :key="index">
            <div style="display:flex">
              <div class="goods_img">
                <img :src="item.goods_cover" />
              </div>
              <div>
                <div style="color:#4B4949">{{item.goods_name}}</div>
                <div class="newpeople">会员价： ￥{{item.goods_discount_price}}</div>
                <div class="markPrice">原价： ￥{{item.goods_price}}</div>
                <div class="submitBtn1">
                  <div class="submitText">热销：</div>
                  <van-progress class="progress" v-if="item.goods_sales/(item.goods_storage+item.goods_sales)!=1" color="#FF5600" stroke-width="10" :percentage="((item.goods_sales/(item.goods_storage+item.goods_sales))*100).toFixed(2)" />
                  <div class="ruch" @click="ruchBtn(item.goods_id,1)">立即抢购</div>
                </div>
              </div>
            </div>
          </van-swipe-item>
        </van-swipe>
      </div>
    </div>
    <!-- 商品列表 -->
    <vue-loadmore :on-refresh="onRefresh" :on-loadmore="onLoad" :finished="finished">
      <div class="newBox1" v-for="(item,index) in dataList.goods" :key="index">
        <div>
          <div style="display:flex">
            <div class="goods_img">
              <img :src="item.goods_cover" />
            </div>
            <div>
              <div style="color:#4B4949">{{item.goods_name}}</div>
              <div class="newpeople" >会员价： ￥{{item.goods_discount_price}}</div>
              <div class="markPrice" style="text-decoration:line-through">原价： ￥{{item.goods_price}}</div>
              <div class="submitBtn1">
                <div class="submitText">热销：</div>
                <van-progress v-if="item.goods_sales/(item.goods_storage+item.goods_sales)!=1" class="progress" color="#FF5600" stroke-width="10" :percentage="((item.goods_sales/(item.goods_storage+item.goods_sales))*100).toFixed(2)"/>
                <div class="ruch" @click="ruchBtn(item.goods_id)">立即抢购</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </vue-loadmore>
  </div>

</template>
<script>
export default {
  data() {
    return {};
  },
  props: {
    dataList: {
      type: Object
    },
    finished: {
      type: Boolean
    },
    userFrom:{
      type: Object
    },
  },
  methods: {
    ruchBtn(id,type) {
      this.$emit("ruchBtn", id, type);
    },
    onRefresh(done) {
      this.list = [];
      this.page = 1;
      this.finished = false;
      this.fetch();

      done();
    },

    onLoad(done) {
      this.$emit("onLoad");

      done();
    },

    fetch() {
      this.$emit("fetch");
    }
  }
};
</script>
<style>
.newBox {
  width: 750px;
  background: #ffffff;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.05);
  border-radius: 0px 0px 0px 0px;
  opacity: 1;
  font-size: 26px;
  padding: 25px;
  box-sizing: border-box;
  margin: 20px 0
}
.newBox1 {
  width: 690px;
  background: #ffffff;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.05);
  border-radius: 0px 0px 0px 0px;
  opacity: 1;
  font-size: 26px;
  padding: 25px;
  box-sizing: border-box;
  margin: 20px auto
}
.new_title {
  color: #6484ea;
  margin-bottom: 25px;
}
.goods_img {
  width: 219px;
  height: 219px;
  border-radius: 10px 10px 10px 10px;
  opacity: 1;
  background-color: skyblue;
  margin-right: 25px;
}
.goods_img img {
  width: 100%;
  height: 100%;
}
.newpeople {
  width: 260px;
  height: 50px;
  background: #fff2e8;
  border-radius: 3px 3px 3px 3px;
  opacity: 1;
  text-align: center;
  line-height: 50px;
  color: #ff5600;
  margin: 20px 0;
}

.markPrice {
  font-size: 24px;
  color: #b7b7b7;
  margin: 10px 0 20px 0;
}
.submitBtn1 {
  font-size: 22px;
  display: flex;
}
.progress {
  width: 200px;
  margin-top: 30px;
}

.submitText {
  width:80px;
  margin-top: 30px;
}
.ruch {
  width: 144px;
  height: 60px;
  background: #ffe4d0;
  border-radius: 5px 5px 5px 5px;
  opacity: 1;
  border: 1px solid #ff5600;
  text-align: center;
  line-height: 60px;
  font-size: 26px;
  color: #ff5600;
  margin-left: 30px;
}
.vuejs-refresh-head{
  top: 100px;
}
.van-swipe__indicator{
  width:20px;
  height :20px;
  margin-top: 20px
}
.my-swipe{
  height: 300px
}
</style>